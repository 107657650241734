.ant-table td { white-space: nowrap; }

tr.ant-table-row td {
  padding: 0px 0px !important;
}

.ReactTable .rt-tbody .rt-td{
  padding: 0;
}
.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select
{
  padding: 0;
}

.ReactTable .rt-thead.-filters input {
  height: 100%;
}

.ReactTable, 
.ReactTable .rt-tbody .rt-td {
  border-color: #ddd;
}

.ReactTable .rt-thead.-filters .rt-th,
.ReactTable .rt-thead .rt-th {
  border-right: 1px solid #ddd;
}

.ReactTable .rt-thead.-filters .rt-th {
  padding: 1px;
}

.ReactTable .rt-tbody .rt-tr {
  border-top: 1px solid #ddd;
}