.mk_analiz .ant-table-bordered .ant-table-thead > tr> th{
  padding: 0;
}

.mk_analiz th.players {
  background-color:#A3CCFA;
}

.mk_analiz th.dobiv_r {
  background-color:#BBBBBB;
}

.mk_analiz th.dobiv_f {
  background-color:#FFAAAA;
}

.mk_analiz th.dobiv_b {
  background-color:#BBFFBB;
}

.mk_analiz th > div > div {
  padding: 0 5px;
}

.mk_analiz th > div > div ,.mk_analiz th > div {
  text-align: center;
}