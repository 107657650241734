@import '~antd/lib/style/themes/default.less';

.sider {
  min-height: 100vh;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  position: relative;
  z-index: 10;
  // &.fixSiderbar {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   :global(.ant-menu-root) {
  //     overflow-y: auto;
  //     height: ~'calc(100vh - @{nav-header-height})';
  //   }
  // }
  &.light {
    box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
    background-color: white;
    .logo {
      background: white;
      box-shadow: 1px 1px 0 0 @border-color-split;
      h1 {
        color: @primary-color;
      }
    }
    :global(.ant-menu-light) {
      border-right-color: transparent;
    }
  }
}