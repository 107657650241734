.StatColumnVisibleChanger .ant-card-body {
  padding: 0 !important;
}

.StatColumnVisibleChanger  .ant-row {
  margin: 6px;
}

.StatColumnVisibleChanger  .ant-btn {
  padding: 0 10px;
}

.StatColumnVisibleChanger .ant-card-extra {
  padding: 2px 0 !important;
}