/* body {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #d2d6de;
    min-width: 300px;
    min-height: 300px;
    } */

.ant-card-head {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    text-align: center;
    
    }


.login-form {
    /* max-width: 300px; */
  }
.login-form-forgot {
    float: right;
  }
.login-form-button {
    width: 100%;
  }