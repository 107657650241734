.ButtonPlace {
  border-top: 2px solid #1890ff;
  padding: 10px;
}

.TablePlace {
  /* border-top: 2px solid #1890FF;  */
  padding: 0px 10px;
  height: auto;
}
.ant-input.EditingInputsOfTable {
  height: 25px;
}

.ant-btn-primary {
  margin-bottom: 0px;
}

.ant-table-bordered .ant-table-thead > tr > th {
  padding: 0px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}
.ant-table-bordered .ant-table-thead > tr,
.ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid #e8e8e8;
  padding: 0px 5px;
}
.editable-row .ant-form-explain {
  max-width: none;
  text-align: center;
  width: 100%;
  border: 1px solid #f5222d;
  border-radius: 4px;
  background: white;
  position: absolute;
  font-size: 12px;
  margin-top: -4px;
}
