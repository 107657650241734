.logo {
  height: 46px;
  background: #002140;
  padding: 2px 16px;
  text-align: center;
}
.logo > h1 {
  color: #ffffff;
}

.ant-layout-header {
  height: auto;
  position: fixed;
  z-index: 1;
  width: 100%;
}

.ant-layout-sider-zero-width-trigger {
  z-index: 1;
}
.avatar-place {
  vertical-align: middle;
  z-index: 2;
  position: fixed;
  top: 3px;
  right: 8px;
}

.ant-dropdown-menu {
  width: 100px;
}
.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
  padding: 3px 12px;
}

.ant-select-dropdown-round ui {
  width: 100px;
}

.ant-table td { white-space: nowrap; }

tr.ant-table-row td {padding: 0px 0px;}

.ant-select-dropdown-round div {
  width: 54px;
}

.table-left-border {
  border-left: 1px #6d6d6d solid;
}

.table-right-border {
  border-right: 1px #6d6d6d solid;
}


.table-left-right-border {
  border-left: 1px #6d6d6d solid;
  border-right: 1px #6d6d6d solid;
}
